<template>
  <div class="row" style="height: 10vh" />
  <div class="row justify-center">
    <div class="col-8 text-center justify-center">
      <q-img src="../assets/storyset/noSession.gif" style="width: 40vw; height: 65vh;"/>
      <div class="text-h3" style="font-family: Montserrat; font-weight: 700">
        Hmm...we couldn't find that session!
      </div>
      <br />
      <div class="text-h5" style="font-family: Lato; font-weight: 350">
        The session you tried to access doesn't exist, or you don't have access to it. It may also have expired.
      </div>
      <br>
      <q-btn
        @click="$router.replace('/profile')"
        no-caps
        push
        label="Go Home"
        style="
          background-color: #ee7a65;
          border-radius: 20px;
          width: 10vw;
          white-space: nowrap;
          color: black;
          font-family: Montserrat;
          font-weight: 600;
          flex: 2;
        "
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "NoSession",
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gentium+Basic:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@font-face {
  font-family: "Farray";
  src: url("../assets/fonts/FARRAY.otf");
}

.login-header {
  background-image: url("../assets/loginbkg.png");
  background-size: cover;
  height: 92vh;
}

.login-image {
  background-image: url("../assets/tutoring-image-loginpage.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 20px 0 0 20px;
}

.login-dialog {
  background-color: black;
  border-radius: 0 20px 20px 0;
}

.lato {
  font-family: Lato;
}
</style>
<style>
.q-field__messages {
  color: teal !important;
}

.text-negative {
  color: teal !important;
}
</style>
